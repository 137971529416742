import React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps } from "gatsby";
import {
  ImageInterface,
  VideoInterface,
  LocalizedContextInterface,
  Seo,
  SanityColorList
} from "../../types/SanityTypes";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import StructuredData from "../../components/StructuredData";
import { LocalisedPageContext } from "../../types/PageTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import SitemapList from "../../components/SitemapList";
import HeroBanner from "../../components/HeroBanner";

type SitemapQueryProps = {
  sitemap: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: VideoInterface;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    seo: Seo;
    slug: {
      current: string;
    };
    structure: {
      key: string;
      value: string;
    }[];
  };
  sitePages: {
    nodes: {
      path: string;
      pageContext: {
        title: string;
        pageType: string;
      };
    }[];
  };
} & LocalizedContextInterface;

type SitemapPageContext = LocalisedPageContext;

export const query = graphql`
  query SiteMap($_id: String, $language: String) {
    sitemap: sanitySitemap(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      seo {
        metaTitle
        metaDescription
      }
      slug {
        current
      }
      structure {
        key
        value
      }
    }
    sitePages: allSitePage {
      nodes {
        path
        pageContext
      }
    }
    ...LocalizedContext
  }
`;

const SiteMap = (props: PageProps<SitemapQueryProps, SitemapPageContext>) => {
  const { htmlLang, siteUrl, siteName } = useSiteMetadata(props.pageContext.language);
  const structuredData = { siteName };
  const sitemap = props.data.sitemap;
  const sitePages = props.data.sitePages.nodes;
  const localizedPath = withPrefix(getLocalizedPath(sitemap.slug?.current, sitemap._id));

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={sitemap.seo.metaTitle}
      pageType={"sitemap"}
    >
      <StructuredData type={"sitemap"} data={structuredData} language={htmlLang} slug={`/${sitemap.slug.current}/`} />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={sitemap?.seo?.metaTitle}
        description={sitemap?.seo?.metaDescription}
        imageUrl={sitemap?.banner?._rawHeroImage?.asset.url}
        imageAlt={sitemap?.banner?._rawHeroImage?.alt}
      />
      <div className="sitemap-page">
        <HeroBanner
          sanityImage={sitemap?.banner?._rawHeroImage}
          title={sitemap?.banner?.heading}
          alignment={sitemap?.banner?.contentAlignment}
          isVideoBanner={sitemap?.banner?.isVideoBanner}
          video={sitemap?.banner?._rawHeroVideo}
        />
      </div>
      <SitemapList sitePages={sitePages} structure={sitemap?.structure} />
    </Layout>
  );
};

export default SiteMap;
