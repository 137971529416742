import React, { FunctionComponent } from "react";
import { Link } from "../Link";
import Section from "../Section";
import "./styles.scss";

interface PageProps {
  path: string;
  pageContext: {
    title: string;
    pageType: string;
  };
}

interface Category {
  key: string;
  value: string;
}

interface SitemapListProps {
  sitePages: PageProps[];
  structure: Category[];
}

const SitemapList: FunctionComponent<SitemapListProps> = props => {
  const { sitePages, structure } = props;
  const hiddenPages = ["404"];
  const pageCategories = structure.map(category => {
    return {
      category,
      pages: sitePages.filter(
        page =>
          page.pageContext?.pageType &&
          page.pageContext?.pageType === category.key &&
          !hiddenPages.includes(page.pageContext.title)
      )
    };
  });

  const list = pageCategories.map((category, index) => (
    <div className="c-sitemap-category" key={index}>
      <h2 className="c-sitemap-category__title smb-24">{category.category.value}</h2>
      <ul className="c-sitemap-category__list smb-xs-24 smb-sm-36">
        {category.pages.map(page => (
          <li key={page.path} className="c-sitemap-category__list-item">
            <Link _id={page.path} to={page.path}>
              {page.pageContext.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ));

  return <Section sectionClassName="c-sitemap">{list}</Section>;
};

export default SitemapList;
